import React, { useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { Tooltip, useDesignSystemTheme } from 'carpenstreet-designsystem';
import { useLocalStorage } from 'react-use';
import { useTranslation } from 'next-i18next';
import useTouch from '@hooks/useTouch';
import useResize from '@hooks/useResize';
import { clickTagLog } from '@lib/mixpanel/events';
import { TagContainer, TagInner, Tag, bounceUpDown } from '@components/_new_components/Common/ProductCard/Tag/Tags.styles';
import { TagToolTipStorage } from './Tags.constans';

interface Props {
  tags: string[];
  tagIds?: number[];
  isDark?: boolean;
  isDraggable?: boolean;
  isTooltip?: boolean;
  showEllipsisCount?: number;
  showMaxCount?: number;
}

/**
 * 기본 설정된 isDraggable = true의 경우 다음과 같습니다.
 * showEllipsisCount, showMaxCount 값은 무시한다.
 * mouse event 시 드래그 가능하다.
 * touch event 시 아무 일도 일어나지 않는다.
 * resize event 시 리셋한다.
 *
 * showEllipsisWidth = undefined, showEllipsisCount = 4, showMaxCount = 3의 경우 다음과 같습니다.
 * 태그가 4개 이상일 경우, 태그는 2개 노출하고 말줄임 문자 1개 노출합니다.
 *
 * @param tags 태그 명 배열
 * @param isDark 태그 아이템의 다크모드 여부
 * @param isDraggable 드래그 가능 여부
 * @param showEllipsisCount 말줄임 문자를 노출하기 위한 태그 개수
 * @param showMaxCount 말줄임 문자를 포함한 최대 노출가능 태그 개수
 */
export default function Tags({ tags = [], tagIds = [], isDark, isDraggable, isTooltip, showEllipsisCount, showMaxCount, ...rest }: Props) {
  const { t } = useTranslation();
  const theme = useDesignSystemTheme();
  const router = useRouter();
  const [isTooltipDismissed, setIsTooltipDismissed] = useLocalStorage(TagToolTipStorage.MAIN, false);

  // props
  const draggable = isDraggable !== undefined ? isDraggable : isNaN(showEllipsisCount) && isNaN(showMaxCount) ? true : undefined;
  const showTags = draggable ? tags.slice() : tags.length >= showEllipsisCount ? tags.slice(0, showMaxCount - 1) : tags.slice(0, showMaxCount);

  // hooks
  const { touchRefs, touchInit, touchRemove } = useTouch({
    observeTypes: ['mouse'],
    onTouched: (structure) => {
      switch (structure.moved.directionX) {
        case -1: {
          const moveX = Math.max(structure.start.transformX + structure.moved.movedX, -1 * structure.container.scrollX);
          handleDrag({ moveX, scrollX: structure.container.scrollX });
          break;
        }
        case 1: {
          const moveX = Math.min(structure.start.transformX + structure.moved.movedX, 0);
          handleDrag({ moveX, scrollX: structure.container.scrollX });
          break;
        }
        case 0:
        default: {
          //
          break;
        }
      }

      if (structure.isScrambled && !structure.isTouched) {
        // 클릭감지
        if (Math.abs(structure.moved.movedX) < 10) {
          handleTagClick(touchRefs.childRef.current);
        } else {
          resizeInit();
        }
      }
    },
  });
  const { resizeRef, resizeInit, resizeRemove } = useResize({
    debounce: 500,
    onResize: ({ entries: [entry] }) => {
      handleDrag({ moveX: 0, scrollX: Math.max(entry.target.scrollWidth - entry.target.clientWidth, 0) });
      resizeRemove();
    },
  });

  // status
  const [structure, setStructure] = useState({
    draggableBefore: draggable ? false : false,
    draggableAfter: draggable ? true : false,
  });

  const handleDrag = ({ moveX, scrollX }: { moveX: number; scrollX: number }) => {
    if (!touchRefs.containerRef.current) return;
    touchRefs.containerRef.current.style.setProperty('transform', `translateX(${moveX}px)`);
    setStructure((prev) => ({ ...prev, draggableBefore: moveX < 0, draggableAfter: moveX > -1 * scrollX }));
  };

  const handleTagClick = (tagElement: HTMLDivElement) => {
    clickTagLog({ alias: tagElement.innerText, tagType: 'prod card' });
    router.push(`/tag/${tagElement.getAttribute('data-tag-id')}/${tagElement.innerText}`);
  };

  const handleTooltipClick = () => {
    setIsTooltipDismissed(true);
  };

  useEffect(() => {
    if (!draggable) return;
    touchInit();
    return () => {
      touchRemove();
      resizeRemove();
    };
  }, []);

  if (!showTags.length) {
    return null;
  }

  return (
    <TagContainer
      ref={resizeRef.containerRef}
      isDark={isDark}
      draggableBefore={structure.draggableBefore}
      draggableAfter={structure.draggableAfter}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
      {...rest}
    >
      <TagInner ref={touchRefs.containerRef}>
        {showTags.map((tag, idx) => {
          if (tagIds.length > 0) {
            if (isTooltip && idx === 0) {
              return (
                <Tooltip
                  key={tag}
                  title={t('product:tagTooltip')}
                  direction="bottom-left"
                  open={!isTooltipDismissed}
                  slotProps={{
                    tooltip: {
                      onClick: handleTooltipClick,
                      sx: {
                        marginLeft: '-8px',
                        cursor: 'pointer',
                        background: theme.palette['color/state/success'],
                        animation: `${bounceUpDown} 1.5s infinite ease-in-out`,
                      },
                    },
                    arrow: {
                      sx: {
                        color: theme.palette['color/state/success'],
                        marginTop: '-0.65em !important',
                        transform: 'translate3d(18px, 0px, 0px) !important',
                      },
                    },
                  }}
                >
                  <Tag data-tag-id={tagIds[idx]}>{tag}</Tag>
                </Tooltip>
              );
            }
            return (
              <Tag key={tag} data-tag-id={tagIds[idx]}>
                {tag}
              </Tag>
            );
          }
          return <Tag key={idx}>{tag}</Tag>;
        })}

        {tags.length > showEllipsisCount && (
          <Tag>
            <svg width="11" height="3" viewBox="0 0 11 3" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M1.4502 2.35742C1.03125 2.35205 0.682129 2.01367 0.6875 1.59473C0.682129 1.17578 1.03125 0.832031 1.4502 0.832031C1.86377 0.832031 2.20752 1.17578 2.21289 1.59473C2.20752 2.01367 1.86377 2.35205 1.4502 2.35742ZM5.67059 2.35742C5.25164 2.35205 4.90252 2.01367 4.90789 1.59473C4.90252 1.17578 5.25164 0.832031 5.67059 0.832031C6.08416 0.832031 6.42791 1.17578 6.43328 1.59473C6.42791 2.01367 6.08416 2.35205 5.67059 2.35742ZM9.89098 2.35742C9.47203 2.35205 9.12291 2.01367 9.12828 1.59473C9.12291 1.17578 9.47203 0.832031 9.89098 0.832031C10.3046 0.832031 10.6483 1.17578 10.6537 1.59473C10.6483 2.01367 10.3046 2.35205 9.89098 2.35742Z"
                fill="#A2A2A4"
              />
            </svg>
          </Tag>
        )}
      </TagInner>
    </TagContainer>
  );
}
